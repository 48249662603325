

.SectionHeading {
    font-family: 'Libre Baskerville', serif;
    padding: 2rem;
    color: #1b1301;
    margin: 0;
    text-align: center;
  }
  
  /* Smaller screens */
  @media (max-width: 600px) {
    .SectionHeading {
      font-size: 1.5rem; /* Smaller font size */
    }
  }
  
  /* Medium screens */
  @media (min-width: 601px) and (max-width: 1200px) {
    .SectionHeading {
      font-size: 2.5rem; /* Medium font size */
    }
  }
  
  /* Larger screens */
  @media (min-width: 1201px) {
    .SectionHeading {
      font-size: 3rem; /* Larger font size */
    }
  }
  