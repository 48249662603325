.HomeContainer{
    /* border-radius: 1.25rem 1.25rem 0rem 0rem; */
    border-radius: 0;

/* background: linear-gradient(180deg, #F1DCA7 0%, #D2BBA0 100%), #FFF; */
}

/* .SectionTitle{
    width: 6.375rem;
height: 1.625rem;
flex-shrink: 0;
    border-radius: 1.25rem 0rem;
background: #FFCB69;

color: #000;
text-align: center !important;
font-family: Droid Sans;
font-size: 0.5625rem;
font-style: normal;
font-weight: 700;
line-height: normal;
}

*/


.SectionTitle {
    left: 0;
    width: auto;
    height: 1.625rem;
    flex-shrink: 0;
    /* border-radius:  1.25rem 0.0625rem 3.3125rem 0.125rem; */
    border-radius:  0;

    background: #FFCB69;
    color: #000;
    text-align: center !important;
    /* font-family: 'Roboto', sans-serif; */
    font-family: 'Libre Baskerville', serif;
    font-style: normal;
    font-weight: 700;
    line-height: 1.625rem; /* Adjust the line-height property to match the height of the block */
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 5rem;
    padding:  1rem;

}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  .fadeIn {
    animation: fadeIn 1s ease-out;
  }
  