.events-page {
    background: #fff;
    /* padding: 1rem; */
  }
  
  .events-header {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 1rem;
    background-image: url('https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.freepik.com%2Fphotos%2Fbook&psig=AOvVaw0EC2An4MXNCSYNswB8w53F&ust=1705342801629000&source=images&cd=vfe&opi=89978449&ved=0CBMQjRxqFwoTCKi0npG_3YMDFQAAAAAdAAAAABAD'); 
    color: white;
  }
  
  .event-content{
    padding: 1rem;
  }
  .event-header {
    padding: 1rem;

  }
  .event-card {
    border: 1px dashed #ccc;
    /* padding: 1rem; */
    margin-bottom:
    1rem;
  background: #fdf5e7;
}

.event-mode {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.event-location .event-venue {
  color: red; /* Or use a class to set the icon color */
  margin-right: 0.5rem;
}

.event-location .event-address {
  display: block; /* Or inline-block, depending on your layout */
}

.event-dates {
  font-size: 0.85rem;
  margin: 0.5rem 0;
}

.event-actions {
    padding-top: 1rem;
  /* text-align: center; */
}

.details-button, .preorder-button {
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
  background-color: #ffb705;
  cursor: pointer;
}

/* You can add :hover states or more complex styling as needed */
  