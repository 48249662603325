@keyframes shineAnimation {
  0% {
    background-position: -200% center;
  }
  100% {
    background-position: 200% center;
  }
}

.shineButton {
  position: relative;
  overflow: hidden;
  background-color: #e8b96b; 
  color: black;
  text-transform: unset;
  border: none;
  align-items: flex-start;

  /* Adding the shine effect */
  background: linear-gradient(
    to right,
    #f4d35e 0%,
    rgba(255, 190, 11, 0.8) 50%,
    #f4d35e 100%
  );
  background-size: 200% auto;
  animation: shineAnimation 3.5s linear infinite;
}

.drawer{
  display: none ;
}


.navBar{
  display: block ;
}



/* .vl {
  border-left: 2px solid rgb(14, 14, 14);
  height: 70px;
  position: absolute;
  
  
  top: 0;
}

@media only screen and (min-width: 480px) {
  
  .notVisibleOnPhone {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  
  .notVisibleOnPhone {
    display: none;
  }

  .rootheader {
    padding: 0;
  }
}

.headerBlock {
  
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 650px) {
  .headerBlock {
    all: unset;
    display: flex;
    flex-direction: column;
  }

  .about,
  .icons,
  {
    width: 100%; 
    margin-bottom: 10px; 
  }
}





.header-container {
  display: grid;
  grid-template-rows: auto auto;
}

.appbar, .sub-appbar {
  box-shadow: unset;
  background-color: #c6ac8f;
}

.toolbar, .sub-toolbar {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 10px;
  justify-content: center;
}

.logo-container, .search-bar, .nav-buttons {
  display: flex;
  align-items: center;
}

.logo-text, .nav-button {
  text-decoration: none;
  color: #1b1303;
} */


.header-container {
  display: grid;
  grid-template-rows: auto auto;
}

.appbar, .sub-appbar {
  box-shadow: unset;
  background-color: #c6ac8f;
  backdrop-filter: saturate(180%) blur(20px) !important;

}

.toolbar, .sub-toolbar {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 10px;
  justify-content: center;
}

.logo-container, .search-bar, .nav-buttons {
  display: flex;
  align-items: center;
}

.logo-text, .nav-button {
  text-decoration: none;
  color: #1b1303;
}


@media screen and (max-width: 1181px) {
  .drawer {
    display: block;
  }
  .header-container{
    display: none ;
  } 
}