.mainPrice{
all:unset;
font-weight: 700;
color: rgb(17, 17, 17);
} 

.mrp{
all:"unset";
text-decoration: line-through;
padding-left: 0.5rem;
font-size: 0.9rem;
font-size:smaller;

}

.discount{
    background-color: #ffb703;
    margin-left: 5px;
    /* padding:0.2rem; */
    font-size:smaller;
    padding: 2px;
    border-radius: 100rem;
}