*,
*::before,
*::after {
  box-sizing: border-box;
}

.img-slider-img {
  
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
  flex-shrink: 0;
  flex-grow: 0;
  transition: translate 300ms ease-in-out;
}

.img-slider-button {
  all: unset;
  display: block;
  position: absolute;
  padding: 1rem;
  cursor: pointer;
  bottom: 15rem;
  width: 3rem;
  height: 3rem;
  filter: brightness(100%) invert(48%);
}

.img-slider-button > * {
  stroke: white;
  fill: white;
  width: 3rem;
  height: 3rem;
  filter: brightness(100%) invert(100%);
  opacity: 0.7;
}

.img-slider-button:hover {
  filter: drop-shadow(0px 0px 3px white);
  transition: filter 100ms ease-in-out;
}

.img-slider-dot-btn {
  filter: brightness(500%) invert(100%);
  all: unset;
  display: block;
  cursor: pointer;
  bottom: 15rem;
  width: 1rem;
  height: 1rem;
  transform: scale(0.01);
}

.responsiveDiv {
  /* width: 100%; */
  height: auto;
  display: flex;
  overflow: hidden;
  margin: 0 auto;
  background-color: #fdf5e7;
}

/* Media query for large screens - adjust the breakpoint as necessary */
@media (min-width: 325px) {
  /* This is typically the width for 'large' devices */
  .img-slider-img {
    /* border-radius: 25px; */
    border-radius: 0;

    padding: 1rem;
  }

  .responsiveDiv {
    width: 100%;
    /* padding: 2rem; */
  }
}

@media (min-width: 1186px) {

  .responsiveDiv {
    width: 70%;
    /* padding: 2rem; */
  }

  /* This is typically the width for 'large' devices */
  .img-slider-img {
    
    /* border-radius: 50px; */
    border-radius: 0;

    padding: 2rem;
  }
}
