.heading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.products-page {
  padding: 2rem;
  background-color: #efece5;
}

.products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
}

.product-card {
  border-radius: 8px;
  padding: 1rem;
  overflow: hidden;
  position: relative;
}

.product-card {
  max-width: 70%;
}
.product-card img {
  max-width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

.product-card h3 {
  margin: 0.5rem 0;
}

.product-card p {
  font-size: 0.9rem;
}

.product-card {
  max-width: 100%; /* Ensure the card takes full width */
  border-radius: 8px;
  padding: 1rem;
  overflow: hidden;
  position: relative;
  display: flex; /* Use flex to align the card content */
  flex-direction: column; /* Stack children vertically */
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
}

.product-card img {
  max-width: 100%;
  height: auto;
  margin-bottom: 1rem;
  border-radius: 8px; /* Adjust the border-radius as per your design */
}
.products-page {
  padding: 2rem;
  background-color: #efece5;
}

.products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-gap: 1rem;
  align-items: center; /* This will vertically center the cards in each row */
}

.product-card {
  border-radius: 8px;
  padding: 1rem;
  overflow: hidden;
  position: relative;
  display: flex; /* Using flex to center content within the card */
  flex-direction: column; /* Stacks children vertically */
  justify-content: center; /* Centers content vertically */
  align-items: center; /* Centers content horizontally */
  max-width: 100%; /* Takes full width of the grid column */
}

.product-card img {
  max-width: 100%;
  height: auto;
  margin-bottom: 1rem;
  border-radius: 8px;
}

.product-card h3 {
  margin: 0.5rem 0;
}

.product-card p {
  font-size: 0.9rem;
}
/* Mobile view: 2 columns */
@media (max-width: 700px) {
  .products-page {
    padding: unset;
  }
  .products-grid {
    padding: unset !important;
    grid-template-columns: repeat(
      2,
      1fr
    ); /* This will create two columns on mobile */
  }
  .product-card img {
    width: 100%; /* Increase image width, you can adjust the percentage as needed */
    border-radius: 10px !important;
    margin: 0 auto; /* This centers the image in the card */
  }

  /* Optionally, you can also adjust the product-card size if needed */
  
  .product-card {
    max-width: 100%; /* Ensure the card takes full width */
    border-radius: 8px;
    padding: 1rem;
    overflow: hidden;
    position: relative;
    display: flex; /* Use flex to align the card content */
    flex-direction: column; /* Stack children vertically */
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
  }
  
}
